import { inject, Injectable } from '@angular/core';
import {
  joinRequestResult,
  RequestResult,
} from '@memberspot/frontend/shared/util/elf';
import { CustomUserProperty } from '@memberspot/shared/model/custom-user-property';
import { createStore } from '@ngneat/elf';
import {
  deleteEntities,
  selectManyByPredicate,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities';
import { RouterRepository } from '@ngneat/elf-ng-router-store';
import { auditTime, Observable, switchMap } from 'rxjs';

const customPropertiesStore = createStore(
  { name: 'customUserProperties' },
  withEntities<CustomUserProperty>(),
);

@Injectable({ providedIn: 'root' })
export class CustomUserPropertiesStoreService {
  private _routerRepository = inject(RouterRepository);
  private _store = customPropertiesStore;

  selectCustomProperties(): Observable<
    RequestResult & { data: CustomUserProperty[] }
  > {
    return this._routerRepository.selectParams('schoolId').pipe(
      auditTime(0),
      switchMap((schoolId) => this.selectCustomPropertiesForSchool(schoolId)),
    );
  }

  selectCustomPropertiesForSchool(schoolId: string) {
    return this._store.pipe(
      selectManyByPredicate((prop) => prop.schoolId === schoolId),
      joinRequestResult(['custom-user-properties', schoolId]),
    );
  }

  selectLinkCustomProperties() {
    return this._routerRepository.selectParams('schoolId').pipe(
      auditTime(0),
      switchMap((schoolId) =>
        this.selectLinkCustomPropertiesForSchool(schoolId),
      ),
    );
  }

  selectLinkCustomPropertiesForSchool(schoolId: string) {
    return this._store.pipe(
      selectManyByPredicate(
        (prop) => prop.type === 'externalLink' && prop.schoolId === schoolId,
      ),
      joinRequestResult(['custom-user-properties', schoolId]),
      auditTime(0),
    );
  }

  upsertProperty(property: CustomUserProperty) {
    this._store.update(upsertEntities(property));
  }

  upsertProperties(properties: CustomUserProperty[]) {
    this._store.update(upsertEntities(properties));
  }

  removeProperty(property: CustomUserProperty) {
    this._store.update(deleteEntities(property.id));
  }
}
